import React from 'react';
import { Route, Routes } from "react-router-native";
import Appbar from './appbar.jsx';
import Aboutpage from './aboutpage.jsx'
import Mainpage from './mainpage.jsx'
import Expaction from './expaction.jsx'
import Ofexpaction from './ofexpaction.jsx'
import Prolongedpain from './prolongedpain.jsx'

const Main = () => {
    return (
        <div className="App">
            <Appbar />
            <Routes>
                <Route path="/" element={<Mainpage/>}/>

                <Route path="/aboutpage" element={<Aboutpage/>}/>

                <Route path="/expaction" element={<Expaction/>}/>

                <Route path="/ofexpaction" element={<Ofexpaction/>}/>

                <Route path="/prolongedpain" element={<Prolongedpain/>}/>

            </Routes>
        </div>
    );
}

export default Main;