import { StyleSheet, View, Text, Image} from 'react-native';
import React from "react";

const styles = StyleSheet.create({
    image: {
        width: '80vw',
        height: '80vw',
        alignSelf: 'center'
    },
    quoteText: {
        fontSize: '1.8em',
        fontWeight: 'bold',
        fontStyle: 'italic',
        textAlign: 'center',
        marginVertical: '50px'
    },
    hexpaction: {
        fontSize: '1.8em',
        textDecorationLine: 'underline',
        textAlign: 'center',
        marginVertical: '50px'
    },
    articles: {
        textAlign: 'center',
        fontSize: '1.2em',
        lineHeight: '40px',
    },
    action: {
        textAlign: 'center',
        fontSize: '1.4em',
        paddingVertical: '50px',
        lineHeight: '2em',
    },
    line: {
        width: '30vw',
        borderBottomWidth: '2px',
        alignSelf: 'center',
        marginTop: '50px'
    },
    expaction: {
        textAlign: 'justify',
        fontSize: 'min(4vw, 1.6em)',
        paddingVertical: '50px',
        lineHeight: '2em',
    },
    ofexp: {
        fontWeight: 'bold',
        fontSize: '30px',
        textAlign: 'center'
    },
    ofexp2: {
        textAlign: 'center',
        fontSize: 'min(4vw, 1.6em)',
        paddingVertical: '50px',
        lineHeight: '2em',
        marginHorizontal: '15vw',
    },
    adimage: {
        width: '80vw',
        height: '115vw',
        alignSelf: 'center',
        marginTop: '2vw'
    },
    ytlink: {
        position: 'absolute',
        margin: 'auto',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -0%)',
        paddingBottom: '2vw'
    },
    pathimage: {
        width: '80vw',
        height: '44vw',
        alignSelf: 'center',
        marginBottom: '2vw'
    },
    roleimage: {
        width: '80vw',
        height: '60vw',
        alignSelf: 'center'
    },
    mainView: {
        paddingHorizontal: '10vw'
    }
});

const Expaction = () => {
    return (
        <View style={styles.mainView}>
           <Text style={styles.quoteText}>”Vain kokenut voi käsittää kokemusta.”</Text>
           <Image style={styles.image} source={require('../assets/unthinkablepain.jpg')}/>
           <Image style={styles.adimage} source={require('../assets/ad.png')}/>
           <Text style={styles.hexpaction}>Kokemustoimintakeikat:</Text>
           <Text style={styles.articles}>{'Kirjoituksia kipupuomi-lehteen: \n1/2023 Kivun kokemustoimijana harjoitusvastaanotolla \n2/2023 Virtauskokemuksia vetten äärellä ja soiden syleilyssä \n4/2023 Näkökulmia oman näköisen ja kokoisen toimijuuden rakentamiseen'}</Text>
           <View style={styles.line}></View>
           <Text style={styles.action}>{'Tampereen yliopiston lääkäriopiskelijoiden vuorovaikutuskoulutuksessa kokemuskouluttajana  \nTeemaillan alustus: Psykologinen joustavuus kivunhallintakeinona ja hyvinvoinnin lähteenä \nApuvälinemessuilla 2023 Suomen Kipu ry:n messuosastolla'}</Text>
           <Text style={styles.ofexp}>{'Minä kokemustoimijana: '}</Text>
           <Text style={styles.ofexp2}>{'Siitä on lähdettävä, että tähän on tultu. Tähän lauseeseen kiteytyy kokemustoimijuuteni ydin. Takana on haastava elämänvaihe, joka omalla tavallaan jatkuu edelleen, mutta suhteeni siihen on muuttunut ratkaisevasti. Aiemmat elämäni tarinat ovat osa minua, eikä niitä voi pyyhkiä pois. Hyväksymällä haasteet, joiden kautta tähän on tultu, ja sen, että entiseen normaaliin ei ole paluuta, voi alkaa katsomaan eteenpäin ja ottaa uuden suuntiman kompassiin. \nOlen Suomen Kipu ry:n kouluttama kokemustoimija ja Kokemustoimintaverkoston kouluttama kehittäjä-vaikuttaja. Olen käynyt oman elämäni korkeakoulua pääaineena krooninen kipu ja sivuaineina työkykyisyyden heikkeneminen ja masennus. Lisäksi minulla on sosiaalialan ylempi ammattikorkeakoulututkinto ja ammatillisen opettajan pätevyys. \nOmalla toipumismatkallani haastavat elämänkokemukset ovat viimein alkaneet näyttäytyä hieman positiivisemmassa valossa. Haluan hyödyntää omia kokemuksiani ja ammatillista osaamistani parempien palveluiden sekä ihmisten hyvinvoinnin edistämiseen. \nLaadukkaiden palveluiden tuottamisen paras lähtökohta on yhdistää ammatillinen osaaminen, asiantuntijatieto sekä kokemustieto ja -ymmärrys. Olen opiskellut palvelumuotoilua ja minulla on myös käytännön kokemusta kehittämistyöstä.'}</Text>
        </View>
    );
}

export default Expaction;