import { StyleSheet, View, Text, Image} from 'react-native';
import { Link } from 'react-router-native';

const styles = StyleSheet.create({

    topView: {
        width: '100vw',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        backgroundColor: '#FFFFFF',
        alignItems: 'center',
        paddingVertical: '40px',
        borderBottomColor: '#000000',
        borderBottomWidth: '2px',
        //paddingHorizontal: '40px'
    },
    bottomView: {
        alignItems: 'center'
    },
    name: {
        textAlign: 'center',
        fontSize: 'min(max(4vw, 0.1em), 42px)',
        fontWeight: 'bold',
        marginBottom: '10px',
        fontFamily: 'Caveat'
    },
    infoText: {
        textAlign: 'center',
        fontSize: 'min(max(3vw, 0.1em), 32px)',
        fontWeight: 'bold',
        lineHeight: 'min(max(2vw, 1em), 4vw)',
        paddingHorizontal: '5px',
        fontFamily: 'Kalam'
    },
    kheLogo: {
        width: '20vw',
        height: '20vh'
    },
    riinaImage: {
        width: '16vw',
        height: '16vh',
        marginHorizontal: '16px'
    },
    sceneryImage: {
        width: '60vw',
        height: '30vw',
    },
    quoteText: {
        textAlign: 'center',
        fontSize: '30px',
        paddingVertical: '30px',
        lineHeight: '40px',
        fontStyle: 'italic',
        //fontFamily: 'Reddit Mono'
    },
    welcomeText: {
        textAlign: 'center',
        fontSize: '40px',
        fontWeight: 'bold',
        paddingTop: '80px',
        fontFamily: 'Reddit Mono'
    },
    bannerImage: {
        width: '100vw',
        height: '30vw'
    },
    contactText: {
        textAlign: 'center',
        fontSize: '40px',
        fontWeight: 'bold',
        paddingVertical: '40px',
        fontFamily: 'Reddit Mono',
        textDecorationLine: 'underline'
    },
    intro: {
        textAlign: 'center',
        fontSize: '40px',
        marginHorizontal: '20vw',
        paddingTop: '2vh'
    },
    imageButton: {
        width: '20vw',
        height: '20vw',
        alignSelf: 'center',
        marginTop: '4vw'
    },
});

const Mainpage = () => {
    return (
        <View>
            {/* <View style={styles.topView}>
                <View>
                    <Image resizeMode='contain' style={styles.kheLogo} source={require('../assets/khelogo.jpg')} />
                </View>
                <View>
                    <Text style={styles.name}>Riina Lehtinen</Text>
                    <Text style={styles.infoText}>{'Sosionomi YAMK\nAmmatillinen opettaja AmO\nPitkittyneen kivun kokemustoimija'}</Text>
                </View>
                <View>
                    <Image resizeMode='contain' style={styles.riinaImage} source={require('../assets/riina.jpg')} />
                </View>
            </View> */}
            <View>
                <Image resizeMode='cover' style={styles.bannerImage} source={require('../assets/banner2.png')} />
            </View>
            <View style={styles.bottomView}>
                <Text style={styles.quoteText}>{'”Käsitykseen käsittämättömästä kivusta”\n”Kokemuksella, asiantuntijuudella, yhteistyöllä”'}</Text>
                <Image resizeMode='cover' style={styles.sceneryImage} source={require('../assets/bcard.png')} />
                <Text style={styles.welcomeText}>{'TERVETULOA SIVUILLENI!\nHienoa, että löysit tänne ☺️'}</Text>
            </View>
            <View>
                <Text style={styles.intro}>Olen kestävän hyvinvoinnin edistäjä sosiaalipedagogisella työotteella. Innostun uuden oppimisesta ja luova kehittämistyö on minulle lähellä sydäntä.</Text>
                <Text style={styles.intro}>Toimin pitkittyneen kivun kokemustoimijana ja koulutan aiheeseen liittyen. Lähestymistapani pitkittyneeseen kipuun on ratkaisukeskeinen sekä toiveikkuutta vahvistava ja perustuu biopsykososiaaliseen viitekehykseen. Teen kokemustoimijakeikkoja erilaisin teemoin ja tilaajataho voi vaikuttaa niiden sisältöön omien tarpeidensa ja toiveidensa mukaisesti. Kohderyhmiä voivat olla esimerkiksi sote-alan ammattilaiset ja opiskelijat, kipukroonikot ja heidän läheisensä sekä kuntoutuksen asiakkaat. Lisäksi voin toimia erilaisissa työryhmissä kehittämässä toimintatapoja ja palveluita.</Text>

                <Link href="mailto:riina.a.lehtinen@gmail.com" style={styles.imageButton}>
                    <Image style={styles.imageButton} source={require('../assets/buttons/1.png')} />
                </Link>
                <Link to="/aboutpage" style={styles.imageButton}>
                    <Image style={styles.imageButton} source={require('../assets/buttons/2.png')} />
                </Link>
                <Link to="/ofexpaction" style={styles.imageButton}>
                    <Image style={styles.imageButton} source={require('../assets/buttons/3.png')} />
                </Link>
                <Link to="/expaction" style={styles.imageButton}>
                    <Image style={styles.imageButton} source={require('../assets/buttons/4.png')} />
                </Link>
                <Link to="/prolongedpain" style={styles.imageButton}>
                    <Image style={styles.imageButton} source={require('../assets/buttons/6.png')} />
                </Link>
                <Link href="https://nuotioranta.blogspot.com" style={styles.imageButton}>
                    <Image style={styles.imageButton} source={require('../assets/buttons/7.png')} />
                </Link>
            </View>
        </View>
    );
}

export default Mainpage;