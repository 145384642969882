import './App.css';
import React from 'react';
import { NativeRouter } from 'react-router-native';
import Main from './components/main.jsx'
import { StyleSheet } from 'react-native';

function App() {
  return (
    <NativeRouter>
      <Main />
    </NativeRouter>
  );
}

export default App;
