import { StyleSheet, View, Text, Image} from 'react-native';
import React from "react";

const styles = StyleSheet.create({
    ytlink: {
        position: 'absolute',
        margin: 'auto',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -0%)',
    }
})

const Prolongedpain = () => {
    return (
        <View>
            <iframe style={styles.ytlink} width="560" height="315" src="https://www.youtube.com/embed/bhyglndwKZQ?si=dg-9vuFV34zM704D" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </View>
    );
}

export default Prolongedpain;