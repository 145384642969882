import React from 'react';
import { StyleSheet, View, Text } from 'react-native';
import { TouchableWithoutFeedback } from 'react-native-web';
import { Link } from 'react-router-native';

const styles = StyleSheet.create({
    flexContainer: {
        fontSize: 'min(max(4vw, 0.1em), 2em)',
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: '#325532',
        justifyContent: 'center',
        paddingVertical: '10px',
        paddingHorizontal: '1px'
    },

    linkText: {
        fontSize: '2vw',
        fontWeight: 'bold',
        color: '#FFFFFF',
        backgroundColor: '#325532',
        paddingHorizontal: '6px',
        fontFamily: 'Reddit Mono'
    }
})

const Appbar = () => {
    return (
        <View style={styles.flexContainer}>
            <Link to="/" component={TouchableWithoutFeedback}>
                <Text style={styles.linkText}> Etusivu </Text>
            </Link>
            <Link to="/aboutpage" component={TouchableWithoutFeedback}>
                <Text style={styles.linkText}> Minusta </Text>
            </Link>
            <Link to="/expaction" component={TouchableWithoutFeedback}>
                <Text style={styles.linkText}> Kokemustoiminta </Text>
            </Link>
            <Link to="/ofexpaction" component={TouchableWithoutFeedback}>
                <Text style={styles.linkText}> Kokemustoiminnasta </Text>
            </Link>
            <Link to="/prolongedpain" component={TouchableWithoutFeedback}>
                <Text style={styles.linkText}> Pitkittynyt Kipu </Text>
            </Link>
            <Link href="https://nuotioranta.blogspot.com" component={TouchableWithoutFeedback}>
                <Text style={styles.linkText}> Blogi </Text>  
            </Link>
        </View>
    );
}

export default Appbar;