import { StyleSheet, View, Text, Image} from 'react-native';
import React from "react";

const styles = StyleSheet.create({
    headText: {
        fontSize: '1.8em',
        fontWeight: 'bold',
        textAlign: 'center',
        marginVertical: '100px',
        fontFamily: 'Reddit Mono'
    },
    aboutText: {
        fontSize: 'min(4vw, 1.6em)',
        fontWeight: 'bold',
        marginHorizontal: '20vw',
        marginVertical: '50px',
        textAlign: 'center',
        fontFamily: 'Reddit Mono'
    },
    treeImage: {
        width: 'min(60vw, 1000px)',
        height: 'min(50vw, 800px)',
        alignSelf: 'center'
    }
});

const Aboutpage = () => {
    return (
        <View>
            <Text style={styles.headText}>Taipuu ja taittuu elämän myrskyissä, mutta ei katkea…</Text>
            <Image style={styles.treeImage} source={require('../assets/bentTree.jpg')} />
            <Text style={styles.aboutText}>{'Olen sosiaalialan ammattilainen ja kokemuksen kautta asiantuntija elämisestä kroonisen kivun kanssa. Haastava elämäntilanne kuten krooninen kipu, voi ajan myötä muuttua merkitykselliseksi. Haluan hyödyntää omaa kokemustarinaani laajemmassa yhteydessä tiedon ja ymmärryksen lisäämiseksi asioista, laadukkaampien palveluiden edistämiseksi, inhimillisen kärsimyksen vähenemiseksi sekä hyvinvoinnin lisääntymiseksi. \n\nOlen omalla kipumatkallani päässyt siihen vaiheeseen, että olen pikkuhiljaa voimaantunut passiivisesta palveluiden vastaanottajasta aktiiviseksi toimijaksi. Kokemustoimijana hyödynnän omaa ammatillista osaamistani sekä käytännön tietoa ja ymmärrystä, joka on syntynyt omien kokemusteni kautta. Tahdon jakaa kokemusperäistä ja teoreettista tietoa pitkittyneestä kivusta kipuoireisille ja heidän parissaan toimiville sekä synnyttää uutta tietoa ja oivalluksia. Syvällinen käsitys pitkittyneestä kivusta tuottaa laadukkaita tukitoimia ja edistää kipuoireisten hyvää ja ihmisarvoista elämää.'}</Text>
        </View>
    );
}

export default Aboutpage;